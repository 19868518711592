import { shallowMount } from '@vue/test-utils';
import CampaignCountdownCms from '../CampaignCountdownCms.vue';
import testCaseWrapper from '~/helpers/test/testCaseWrapper';

function createWrapper(props = {}) {
  return shallowMount(CampaignCountdownCms, {
    propsData: props,
  });
}

const defaultData = {
  backgroundColor: '#000',
  textColor: '#fff',
  countdownStartDate: '',
  campaignEndDate: '2024-12-31T23:59:59Z',
};

const testCasesForSnapshots = [
  {
    condition: 'When countdown with colors and end date are provided.',
    assertion: 'Renders countdown.',
    ...defaultData,
  },
];

testCaseWrapper(
  'CampaignCountdownCms.vue',
  createWrapper,
  testCasesForSnapshots,
);
