



















import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';

export default defineComponent({
  name: 'CampaignCountdownSection',
  props: {
    value: {
      type: Number,
      required: true,
    },
    unit: {
      type: String as PropType<VueI18n.TranslateResult>,
      required: true,
    },
    noColon: {
      type: Boolean,
      default: false,
    },
  },
});
