








import { defineComponent } from '@nuxtjs/composition-api';
import CampaignCountdown from '~/components/molecules/Product/CampaignCountdown/CampaignCountdown.vue';
export default defineComponent({
  name: 'CampaignCountdownCms',
  components: {
    CampaignCountdown,
  },
  props: {
    countdownStartDate: {
      type: String,
      default: '',
    },
    campaignEndDate: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '#1A1A1A',
    },
    textColor: {
      type: String,
      default: '#fff',
    },
  },
});
